<template>
    <v-dialog
        ref="dialog"
        v-model="modal"
        :persistent="persistent"
        :dense="dense"
        scrollable
        :fullscreen="fullscreen"
        hide-overlay
        :width="width">
        <template v-slot:activator="{ on, attrs }">
            <slot name="activator" v-bind:on="on" v-bind:attrs="attrs" v-bind:openDialog="openDialog">
                <v-btn
                    v-if="!hideButton"
                    :disabled="disabled"
                    :loading="isLoading"
                    :icon="icon != null && text == null"
                    :text="icon == null && text != null"
                    :rounded="rounded"
                    :small="small"
                    :large="large"
                    :block="block"
                    v-bind="attrs" 
                    v-on="on"
                    @click.stop="openDialog"
                    :class="buttonClass"
                    :title="title">
                    <slot name="btn">
                        <v-icon :left="icon != null && text != null" :small="small" :class="iconClass">{{ icon }}</v-icon>
                        <div v-if="text">{{ text }}</div>
                    </slot>
                </v-btn>
            </slot>
        </template>
        <slot name="body" v-bind:item="data" v-bind:ok="save">
            <v-card 
                :loading="isLoading"
                :class="cardClass"
                :dark="dark"
                dense
                :height="height">
                <slot name="header">
                    <v-card-title>{{ label }}</v-card-title>
                </slot>
                <v-divider />
                <v-card-text :class="cardTextClass">
                    <v-form
                        v-if="data"
                        v-model="form" 
                        class="mx-sm-4" 
                        ref="form"
                        :dense="dense"
                        scrollable>
                        <slot v-bind:item="data" v-bind:ok="save" />
                    </v-form>
                </v-card-text>
                <v-card-actions v-if="!hideActions">
                    <v-btn v-if="cancelText" text @click="close">{{ cancelText }}</v-btn>
                    <v-spacer />
                    <v-btn v-if="okText" :disabled="!(onCanOK == null || onCanOK(data) )" text @click="save">{{ okText }}</v-btn>
                    <slot name="actions" v-bind:item="data" v-bind:ok="save" />
                </v-card-actions>
                <v-overlay :value="isLoading || loadingMsg != null" absolute class="text-center">
                    <v-progress-circular indeterminate size="64" />
                    <p>{{ loadingMsg || 'Loading' }}</p>
                </v-overlay> 
            </v-card>
        </slot>
        
    </v-dialog>
</template>

<script>
export default {
    name: 'BT-Dialog',
    data: function() {
        return {
            modal: false,
            data: null,
            form: null,
            isLoading: false,
        }
    },
    props: {
        value: null,
        item: null,
        dark: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: true
        },
        block: {
            type: Boolean,
            default: false
        },
        cardClass: {
            type: String,
            default: null
        },
        cardTextClass: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        fullscreen: {
            type: Boolean,
            default: false
        },
        getOnOpen: {
            type: Function,
            default: null
        },
        getOnOpenAsync: {
            type: Function,
            default: null
        },
        height: {
            type: String,
            default: null
        },
        hideActions: {
            type: Boolean,
            default: false
        },
        hideButton: {
            type: Boolean,
            default: false
        },
        show: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: null
        },
        large: {
            type: Boolean,
            default: false
        },
        loadingMsg: {
            type: String,
            default: null
        },
        
        hideToggle: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: 'mdi-plus'
        },
        title: {
            type: String,
            default: null
        },
        text: {
            type: String,
            default: null
        },
        width: {
            type: String,
            default: 'auto'
        },
        okText: {
            type: String,
            default: 'OK'
        },
        cancelText: {
            type: String,
            default: 'Cancel'
        },
        onCanOK: {
            type: Function,
            default: null
        },
        openToggle: {
            type: Boolean,
            default: false
        },
        persistent: {
            type: Boolean,
            default: true
        },
        rounded: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        buttonClass: {
            type: String,
            default: null
        },
        iconClass: {
            type: String,
            default: ''
        },
        showToggle: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        show: function(val) {
            this.modal = val;
        },
        value: function(val) {
            if (val) {
                this.data = val;
            }
        },
        hideToggle: function() {
            this.modal = false;
        },
        item: function(val) {
            if (!this.value && val) {
                this.data = val;
            }
        },
        loading: function(val) {
            this.isLoading = val;
        },
        openToggle: function() {
            this.openDialog();
        },
        showToggle: function() {
            this.modal = true;
        },
    },
    mounted() {
        if (this.getOnOpen == null) {
            if (this.value) {
                this.data = this.value;
            }
            else if (this.item) {
                this.data = this.item;
            }
            else {
                this.data = {};
            }
        }

        this.isLoading = this.loading;
        this.modal = this.show;
    },
    methods: {
        save() {
            this.$emit('ok', this.data);
            this.modal = false;
        },
        async openDialog() {
            if (this.getOnOpenAsync != null) {
                try {
                    this.isLoading = true;
                    this.$forceUpdate();
                    var res = await this.getOnOpenAsync(this.item);
                    this.data = this.getOnOpen != null ? this.getOnOpen(res) : res;
                    this.$emit('open', self.data);
                    this.modal = true;
                }
                finally {
                    this.isLoading = false;
                }
            }
            else if (this.getOnOpen != null) {
                this.data = this.getOnOpen(this.item);
                this.$emit('open', this.data);
                this.modal = true;
            }
            else {
                this.modal = true;
                this.$emit('open', null);
            }
        },
        close() {
            this.$emit('close');
            this.modal = false;
        }
    }
}
</script>